var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.user},[_c('div',{class:_vm.$style.userStickyToggle,on:{"click":_vm.toggle}},[_c('div',{class:[_vm.$style.expandButton, ( _obj = {}, _obj[_vm.$style.expanded] = _vm.isExpanded, _obj )]}),_c('div',{class:_vm.$style.name},[_vm._v(" "+_vm._s(_vm.user.name)+" ")])]),_vm._l((_vm.user.dates),function(date){
var _obj;
return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],key:_vm.user.name + date.date,class:_vm.$style.userData},[_c('div',{class:_vm.$style.toggle,on:{"click":function($event){return _vm.toggleDate(date)}}},[_c('div',{class:[_vm.$style.expandButton, ( _obj = {}, _obj[_vm.$style.expanded] = date.isExpanded, _obj )]}),_c('div',{class:_vm.$style.date},[_vm._v(" "+_vm._s(date.date)+" ")])]),_c('table',{directives:[{name:"show",rawName:"v-show",value:(date.isExpanded),expression:"date.isExpanded"}],class:_vm.$style.table},[_c('thead',[_c('tr',{class:_vm.$style.header},_vm._l((_vm.columns),function(column,number){
var _obj;
return _c('td',{key:column.name + date.date + number,style:({ width: column.width }),on:{"click":function($event){return _vm.changeSort(column)}}},[_c('div',{class:_vm.$style.tdInline},[_vm._v(" "+_vm._s(column.name)+" ")]),_c('img',{class:[
                _vm.$style.tdInline,
                _vm.$style.arrow,
                ( _obj = {}, _obj[_vm.$style.asc] = column.sort == 2, _obj[_vm.$style.hidden] = column.sort == 0, _obj ) ],attrs:{"src":require("@/assets/images/icons/table/sort-arrow.svg")}})])}),0)]),_c('tbody',_vm._l((_vm.getSortedEvents(date.events)),function(event,index){
              var _obj, _obj$1, _obj$2;
return _c('tr',{key:event.eventTime + index + date.date,class:[
            _vm.$style.item,

            ( _obj = {}, _obj[_vm.$style.red] = event.productivity === 0, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.green] = event.productivity === 1, _obj$1 ),
            ( _obj$2 = {}, _obj$2[_vm.$style.yellow] = event.productivity === 2, _obj$2 ) ]},_vm._l((_vm.columns),function(column,number){return _c('td',{key:event[column.field] + date.date + number},[_vm._v(" "+_vm._s(_vm.getDataField(event[column.field], column.field))+" "),(column.field === 'eventTime')?_c('div',{class:_vm.$style.time},[_vm._v(" "+_vm._s(_vm.getTime(event[column.field]))+" ")]):_vm._e()])}),0)}),0)])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }