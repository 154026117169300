<template>
  <scrollable-container>
    <placeholder
      v-show="state === reportEnum.requestAwaiting"
      :text="text"
      @buttonClick="buildReport"
    />
    <loader v-show="state === reportEnum.tableLoading" />
    <container
      v-show="state === reportEnum.tableReady"
      id="printable"
      :class="[
        { [$style.message]: state === reportEnum.requestAwaiting },
        $style.scrollableContent,
      ]"
    >
      <div
        v-if="tableData.length === 0"
        :class="$style.emptyResult"
      >
        Нет данных за указанный период
      </div>

      <activity-table
        v-for="user in tableData"
        :key="user.name"
        :class="$style.name"
        :user="user"
      />
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import { getActivityFeed } from '@/api/methods/reports/users/index';
import Loader from '@/components/common/blocks/loader.vue';
import { getDataField } from '@/views/reports/users/worktime/table-field';
import { getTime } from '@/helpers/format-date';
import ActivityTable from './activity-table.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    ActivityTable,
    Loader,
  },
  data: () => ({
    name: 'Лента активности',
    tableData: [],
    computersArray: [],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    columns: [
      { name: 'Время', field: 'eventTime' },
      { name: 'Путь', field: 'path' },
      { name: 'Описание', field: 'description' },
      { name: 'Заголовок окна', field: 'caption' },
      { name: 'Активность', field: 'activeTime' },
      { name: 'Категория', field: 'group' },
      { name: 'Продуктивность', field: 'productivity' },
    ],
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];

      return tree;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    getCheckedComputersFromTree(node) {
      if (node.type === 'computer' && node.checked) {
        this.computersArray.push(node);
      }
      if (node.type === 'folder') {
        node.children.forEach((child) => this.getCheckedComputersFromTree(child));
      }
    },
    buildReport() {
      this.state = this.reportEnum.tableLoading;
      this.tableData = [];
      this.computersArray = [];

      if (this.computersTree) {
        this.computersTree.forEach((node) => this.getCheckedComputersFromTree(node));
      }

      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersArray
      ) {
        this.promise = getActivityFeed(
          this.computersArray,
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
        ).then(({ data }) => {
          if (data) {
            this.tableData = data.result;

            this.state = this.reportEnum.tableReady;

            const exportArray = [];

            for (let i = 0; i < data.result.length; i++) {
              const { name } = data.result[i];
              for (let x = 0; x < data.result[i].dates.length; x++) {
                const { date } = data.result[i].dates[x];

                for (
                  let y = 0;
                  y < data.result[i].dates[x].events.length;
                  y++
                ) {
                  const resObj = { Пользователь: name, Дата: date };
                  for (let j = 0; j < this.columns.length; j++) {
                    resObj[this.columns[j].name] = getDataField(
                      data.result[i].dates[x].events[y][this.columns[j].field],
                      this.columns[j].field,
                    );
                    if (this.columns[j].field === 'eventTime') {
                      resObj[this.columns[j].name] = getTime(data.result[i].dates[x].events[y][this.columns[j].field]);
                    }
                  }
                  exportArray.push(resObj);
                }
              }
            }

            this.$store.commit('exportData/setActivityFeed', { name: this.name, data: exportArray });
          } else this.state = this.reportEnum.requestAwaiting;
        });
      }
      return this.tableData;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyResult {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
