<template>
  <div :class="$style.user">
    <div
      :class="$style.userStickyToggle"
      @click="toggle"
    >
      <div :class="[$style.expandButton, { [$style.expanded]: isExpanded }]" />
      <div :class="$style.name">
        {{ user.name }}
      </div>
    </div>

    <div
      v-for="date in user.dates"
      v-show="isExpanded"
      :key="user.name + date.date"
      :class="$style.userData"
    >
      <div
        :class="$style.toggle"
        @click="toggleDate(date)"
      >
        <div :class="[$style.expandButton, { [$style.expanded]: date.isExpanded }]" />
        <div :class="$style.date">
          {{ date.date }}
        </div>
      </div>
      <table
        v-show="date.isExpanded"
        :class="$style.table"
      >
        <thead>
          <tr :class="$style.header">
            <td
              v-for="(column, number) in columns"
              :key="column.name + date.date + number"
              :style="{ width: column.width }"
              @click="changeSort(column)"
            >
              <div :class="$style.tdInline">
                {{ column.name }}
              </div>
              <img
                src="@/assets/images/icons/table/sort-arrow.svg"
                :class="[
                  $style.tdInline,
                  $style.arrow,
                  {
                    [$style.asc]: column.sort == 2,
                    [$style.hidden]: column.sort == 0,
                  },
                ]"
              >
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(event, index) in getSortedEvents(date.events)"
            :key="event.eventTime + index + date.date"
            :class="[
              $style.item,

              {
                [$style.red]: event.productivity === 0,
              },
              {
                [$style.green]: event.productivity === 1,
              },
              {
                [$style.yellow]: event.productivity === 2,
              },
            ]"
          >
            <td
              v-for="(column, number) in columns"
              :key="event[column.field] + date.date + number"
            >
              {{ getDataField(event[column.field], column.field) }}
              <div
                v-if="column.field === 'eventTime'"
                :class="$style.time"
              >
                {{ getTime(event[column.field]) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getDataField } from '@/views/reports/users/worktime/table-field';
import { getTime } from '@/helpers/format-date';

export default {
  props: ['user'],
  data: () => ({
    name: 'ActivityFeedTable',
    isExpanded: true,
    columns: [
      { name: 'Время', width: '80px', field: 'eventTime', sort: 2 },
      { name: 'Путь', width: '20%', field: 'path', sort: 0 },
      { name: 'Описание', width: '13%', field: 'description', sort: 0 },
      { name: 'Заголовок окна', width: '20%', field: 'caption', sort: 0 },
      { name: 'Активность', width: '90px', field: 'activeTime', sort: 0 },
      { name: 'Категория', width: '85px', field: 'group', sort: 0 },
      {
        name: 'Продуктивность',
        width: '125px',
        field: 'productivity',
        sort: 0,
      },
    ],
  }),
  computed: {
    sortedColumnIndex() {
      return this.columns.findIndex((x) => x.sort > 0);
    },
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    toggleDate(date) {
      date.isExpanded = !date.isExpanded;
    },
    getSortedEvents(events) {
      return events.slice(0).sort(this.compare);
    },
    compare(a, b) {
      const index = this.sortedColumnIndex;
      const key = this.columns[index].field;
      let a1 = a[key];
      let b1 = b[key];

      if ([1, 2, 3, 5].includes(index)) {
        a1 = a1.toLowerCase();
        b1 = b1.toLowerCase();
      }

      if (key === 'productivity') {
        if (a === 2 || a === 4) a = 1 / a;
        if (b === 2 || b === 4) b = 1 / b;
      }

      if (this.columns[index].sort === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    getDataField(data, field) {
      return getDataField(data, field);
    },
    getTime(data) {
      return getTime(data);
    },
  },
};
</script>

<style lang="scss" module>
.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: 22px;
  z-index: 2;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 4px;
    cursor: pointer;
    vertical-align: middle;
  }
}

.item {
  height: 29px;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }

  &.red {
    background-color: #ffb0b0;

    &:hover {
      background-color: $red;
    }
  }

  td {
    border: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    padding: 5px;
    vertical-align: middle;
  }
}

.time {
  font-weight: 500;
}

.tdInline {
  display: inline-flex;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 11px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.date {
  background-color: $light-gray;
  letter-spacing: -0.24px;
  width: 90px;
  height: 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  color: #4b4b4b;
  margin-top: 10px;
  margin-bottom: 5px;
}

.toggle {
  cursor: pointer;
}

.red {
  background-color: #ffb0b0;

  &:hover {
    background-color: $red;
  }
}

.green {
  background-color: #7cd890;

  &:hover {
    background-color: #1f8d37;
  }
}

.yellow {
  background-color: #ffecbc;

  &:hover {
    background-color: #d49a02;
  }
}

.blue {
  background-color: #94c2e0;

  &:hover {
    background-color: #1970a7;
  }
}

.userData {
  margin-left: 30px;
  position: relative;
}

.name {
  font-weight: 500;
  display: inline-flex;
  background-color: #f5f5f5;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
  margin-right: 12px;
  font-size: 14px;
}

.userStickyToggle {
  position: sticky;
  z-index: 3;
  top: -1px;
  width: 100%;
  line-height: 14px;
  display: inline-flex;
  cursor: pointer;
  margin-left: 12px;
  align-items: center;
}

.user {
  margin: 5px;
}</style>
